var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "row" },
    [
      _c(
        "div",
        { staticClass: "col-12" },
        [
          _c("c-table", {
            ref: "grid",
            attrs: {
              title: "모든 작업계획별 소요자재",
              columns: _vm.grid.columns,
              merge: _vm.grid.merge,
              data: _vm.grid.data,
              gridHeight: _vm.grid.height,
              isTitle: true,
              editable: false,
              hideBottom: true,
              isExcelDown: false,
              filtering: false,
              isFullScreen: false,
              columnSetting: false,
            },
            scopedSlots: _vm._u([
              {
                key: "customArea",
                fn: function ({ props }) {
                  return [
                    [
                      _c("q-btn", {
                        attrs: {
                          flat: "",
                          size: "12px",
                          color: "orange",
                          icon: "chevron_right",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.rowClickDetail(
                              props.row,
                              props.pageIndex
                            )
                          },
                        },
                      }),
                    ],
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "q-dialog",
        {
          attrs: { position: _vm.positionDetail },
          model: {
            value: _vm.dialogDetail,
            callback: function ($$v) {
              _vm.dialogDetail = $$v
            },
            expression: "dialogDetail",
          },
        },
        [
          _c(
            "q-card",
            { staticStyle: { width: "100%" } },
            [
              _c("q-linear-progress", { attrs: { value: 1, color: "pink" } }),
              _c("q-card-section", { staticClass: "row" }, [
                _c("div", {
                  staticClass: "col-12 text-weight-bold-dailog",
                  domProps: {
                    textContent: _vm._s(_vm.selectedRow.materialName),
                  },
                }),
                _c("div", { staticClass: "col-2" }, [
                  _c("div", { staticClass: "mini-dailog-title" }, [
                    _vm._v("자재No"),
                  ]),
                ]),
                _c("div", {
                  staticClass: "col-10",
                  domProps: { textContent: _vm._s(_vm.selectedRow.materialNo) },
                }),
                _c("div", { staticClass: "col-4" }, [
                  _c("div", { staticClass: "mini-dailog-title" }, [
                    _vm._v("용도"),
                  ]),
                ]),
                _c(
                  "div",
                  { staticClass: "col-8" },
                  [
                    _c("c-text", {
                      attrs: {
                        disabled: true,
                        label: "",
                        name: "materialDesc",
                      },
                      model: {
                        value: _vm.selectedRow.materialDesc,
                        callback: function ($$v) {
                          _vm.$set(_vm.selectedRow, "materialDesc", $$v)
                        },
                        expression: "selectedRow.materialDesc",
                      },
                    }),
                  ],
                  1
                ),
                _c("div", { staticClass: "col-4" }, [
                  _c("div", { staticClass: "mini-dailog-title" }, [
                    _vm._v("단가"),
                  ]),
                ]),
                _c(
                  "div",
                  { staticClass: "col-8" },
                  [
                    _c("c-text", {
                      attrs: {
                        disabled: true,
                        type: "number",
                        label: "",
                        name: "unitPrice",
                      },
                      model: {
                        value: _vm.selectedRow.unitPrice,
                        callback: function ($$v) {
                          _vm.$set(_vm.selectedRow, "unitPrice", $$v)
                        },
                        expression: "selectedRow.unitPrice",
                      },
                    }),
                  ],
                  1
                ),
                _c("div", { staticClass: "col-4" }, [
                  _c("div", { staticClass: "mini-dailog-title" }, [
                    _vm._v("수량"),
                  ]),
                ]),
                _c(
                  "div",
                  { staticClass: "col-8" },
                  [
                    _c("c-text", {
                      attrs: {
                        disabled: true,
                        type: "number",
                        label: "",
                        name: "unitEa",
                      },
                      model: {
                        value: _vm.selectedRow.unitEa,
                        callback: function ($$v) {
                          _vm.$set(_vm.selectedRow, "unitEa", $$v)
                        },
                        expression: "selectedRow.unitEa",
                      },
                    }),
                  ],
                  1
                ),
                _c("div", { staticClass: "col-4" }, [
                  _c("div", { staticClass: "mini-dailog-title" }, [
                    _vm._v("소계"),
                  ]),
                ]),
                _c(
                  "div",
                  { staticClass: "col-8" },
                  [
                    _c("c-text", {
                      attrs: {
                        editable: false,
                        label: "",
                        type: "number",
                        name: "priceCost",
                      },
                      model: {
                        value: _vm.selectedRow.priceCost,
                        callback: function ($$v) {
                          _vm.$set(_vm.selectedRow, "priceCost", $$v)
                        },
                        expression: "selectedRow.priceCost",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-12 mini-dailog-btn-impr" },
                  [
                    _c(
                      "q-btn-group",
                      { attrs: { outline: "" } },
                      [
                        _c("c-btn", {
                          attrs: {
                            showLoading: false,
                            label: "닫기",
                            icon: "close",
                          },
                          on: { btnClicked: _vm.dialogClose },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-12" },
        [
          _c("c-table", {
            ref: "grid2",
            attrs: {
              title: "모든 작업계획별 서비스(외주)",
              columns: _vm.grid2.columns,
              merge: _vm.grid2.merge,
              data: _vm.grid2.data,
              gridHeight: _vm.grid2.height,
              isTitle: true,
              editable: false,
              hideBottom: true,
              isExcelDown: false,
              filtering: false,
              isFullScreen: false,
              columnSetting: false,
            },
            scopedSlots: _vm._u([
              {
                key: "customArea",
                fn: function ({ props }) {
                  return [
                    [
                      _c("q-btn", {
                        attrs: {
                          flat: "",
                          size: "12px",
                          color: "orange",
                          icon: "chevron_right",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.rowClickDetail2(
                              props.row,
                              props.pageIndex
                            )
                          },
                        },
                      }),
                    ],
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "q-dialog",
        {
          attrs: { position: _vm.positionDetail2 },
          model: {
            value: _vm.dialogDetail2,
            callback: function ($$v) {
              _vm.dialogDetail2 = $$v
            },
            expression: "dialogDetail2",
          },
        },
        [
          _c(
            "q-card",
            { staticStyle: { width: "100%" } },
            [
              _c("q-linear-progress", { attrs: { value: 1, color: "pink" } }),
              _c("q-card-section", { staticClass: "row" }, [
                _c("div", {
                  staticClass: "col-12 text-weight-bold-dailog",
                  domProps: {
                    textContent: _vm._s(_vm.selectedRow2.vendorName),
                  },
                }),
                _c("div", { staticClass: "col-4" }, [
                  _c("div", { staticClass: "mini-dailog-title" }, [
                    _vm._v("원가요소"),
                  ]),
                ]),
                _c(
                  "div",
                  { staticClass: "col-8" },
                  [
                    _c("c-select", {
                      attrs: {
                        editable: false,
                        codeGroupCd: "WO_COST_FACOTR_CD",
                        type: "edit",
                        itemText: "codeName",
                        itemValue: "code",
                        name: "costFactorCd",
                        label: "원가요소",
                      },
                      model: {
                        value: _vm.selectedRow2.costFactorCd,
                        callback: function ($$v) {
                          _vm.$set(_vm.selectedRow2, "costFactorCd", $$v)
                        },
                        expression: "selectedRow2.costFactorCd",
                      },
                    }),
                  ],
                  1
                ),
                _c("div", { staticClass: "col-4" }, [
                  _c("div", { staticClass: "mini-dailog-title" }, [
                    _vm._v("작업인원"),
                  ]),
                ]),
                _c(
                  "div",
                  { staticClass: "col-8" },
                  [
                    _c("c-text", {
                      attrs: {
                        disabled: true,
                        label: "",
                        type: "number",
                        name: "manCnt",
                      },
                      model: {
                        value: _vm.selectedRow2.manCnt,
                        callback: function ($$v) {
                          _vm.$set(_vm.selectedRow2, "manCnt", $$v)
                        },
                        expression: "selectedRow2.manCnt",
                      },
                    }),
                  ],
                  1
                ),
                _c("div", { staticClass: "col-4" }, [
                  _c("div", { staticClass: "mini-dailog-title" }, [
                    _vm._v("인력단가"),
                  ]),
                ]),
                _c(
                  "div",
                  { staticClass: "col-8" },
                  [
                    _c("c-text", {
                      attrs: {
                        disabled: true,
                        label: "",
                        type: "number",
                        name: "priceCost",
                      },
                      model: {
                        value: _vm.selectedRow2.priceCost,
                        callback: function ($$v) {
                          _vm.$set(_vm.selectedRow2, "priceCost", $$v)
                        },
                        expression: "selectedRow2.priceCost",
                      },
                    }),
                  ],
                  1
                ),
                _c("div", { staticClass: "col-4" }, [
                  _c("div", { staticClass: "mini-dailog-title" }, [
                    _vm._v("인건비"),
                  ]),
                ]),
                _c(
                  "div",
                  { staticClass: "col-8" },
                  [
                    _c("c-text", {
                      attrs: {
                        disabled: true,
                        label: "",
                        type: "number",
                        name: "manCost",
                      },
                      model: {
                        value: _vm.selectedRow2.manCost,
                        callback: function ($$v) {
                          _vm.$set(_vm.selectedRow2, "manCost", $$v)
                        },
                        expression: "selectedRow2.manCost",
                      },
                    }),
                  ],
                  1
                ),
                _c("div", { staticClass: "col-4" }, [
                  _c("div", { staticClass: "mini-dailog-title" }, [
                    _vm._v("자재비"),
                  ]),
                ]),
                _c(
                  "div",
                  { staticClass: "col-8" },
                  [
                    _c("c-text", {
                      attrs: {
                        disabled: true,
                        type: "number",
                        label: "",
                        name: "materialCost",
                      },
                      model: {
                        value: _vm.selectedRow2.materialCost,
                        callback: function ($$v) {
                          _vm.$set(_vm.selectedRow2, "materialCost", $$v)
                        },
                        expression: "selectedRow2.materialCost",
                      },
                    }),
                  ],
                  1
                ),
                _c("div", { staticClass: "col-4" }, [
                  _c("div", { staticClass: "mini-dailog-title" }, [
                    _vm._v("합계"),
                  ]),
                ]),
                _c(
                  "div",
                  { staticClass: "col-8" },
                  [
                    _c("c-text", {
                      attrs: {
                        editable: false,
                        type: "number",
                        label: "",
                        name: "sumCost",
                      },
                      model: {
                        value: _vm.selectedRow2.sumCost,
                        callback: function ($$v) {
                          _vm.$set(_vm.selectedRow2, "sumCost", $$v)
                        },
                        expression: "selectedRow2.sumCost",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-12 mini-dailog-btn-impr" },
                  [
                    _c(
                      "q-btn-group",
                      { attrs: { outline: "" } },
                      [
                        _c("c-btn", {
                          attrs: {
                            showLoading: false,
                            label: "닫기",
                            icon: "close",
                          },
                          on: { btnClicked: _vm.dialogClose2 },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }